<template>
  <div id="app-notification-list">
    <!-- list filters -->
    <v-card class="mb-8">
      <v-card-title>
        {{ t('App Notifications') }}
      </v-card-title>
    </v-card>

    <v-card>
      <v-card-text>
        <v-form
          ref="form"
          class="multi-col-validation mt-6"
        >
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="dataGeneral.title_spa"
                :label="t('notifications.title_spa')"
                dense
                outlined
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="dataGeneral.title_eng"
                :label="t('notifications.title_eng')"
                dense
                outlined
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="dataGeneral.description_spa"
                :label="t('notifications.description_spa')"
                outlined
                :rows="1"
                :rules="[required]"
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-textarea
                v-model="dataGeneral.description_eng"
                :label="t('notifications.description_eng')"
                outlined
                :rows="1"
                :rules="[required]"
              ></v-textarea>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="dataGeneral.button_label_spa"
                :label="t('notifications.button_label_spa')"
                dense
                outlined
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="dataGeneral.button_label_eng"
                :label="t('notifications.button_label_eng')"
                dense
                outlined
                :rules="[required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="dataGeneral.handled_action"
                :label="t('notifications.handled_action')"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-checkbox
                v-model="dataGeneral.show_modal"
                class="ma-auto"
                :label="t('notifications.show_modal')"
                color="secondary"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-checkbox
                v-model="dataGeneral.only_fanaty_user"
                class="ma-auto"
                :label="t('notifications.only_fanaty_user')"
                color="secondary"
              ></v-checkbox>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-row class="justify-center align-center">
                <v-col
                  cols="12"
                  md="7"
                  class="text-center"
                >
                  <v-avatar
                    rounded
                    size="120"
                    class="mx-auto"
                    width="200"
                    height="200"
                    color="primary"
                  >
                    <v-img
                      v-if="imageDataBg || dataGeneral.background"
                      :src="imageDataBg ? urlImageBg : dataGeneral.background"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-medium"
                    >{{ t('notifications.background') }}</span>
                  </v-avatar>
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                >
                  <!-- upload photo -->
                  <div class="my-auto">
                    <v-file-input
                      v-model="imageDataBg"
                      accept="image/png, image/jpg, image/jpeg"
                      :placeholder="t('notifications.select_background')"
                      :label="t('notifications.background')"
                      :prepend-icon="icons.mdiCamera"
                      outlined
                      dense
                      hide-details
                      show-size
                      @change="onFileSelected($event, 'bg')"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-row class="justify-center align-center">
                <v-col
                  cols="12"
                  md="7"
                  class="text-center"
                >
                  <v-avatar
                    rounded
                    size="120"
                    class="mx-auto"
                    width="200"
                    height="200"
                    color="primary"
                  >
                    <v-img
                      v-if="imageData || dataGeneral.image"
                      :src="imageData ? urlImage : dataGeneral.image"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-medium"
                    >{{ t('notifications.image') }}</span>
                  </v-avatar>
                </v-col>

                <v-col
                  cols="12"
                  md="5"
                >
                  <!-- upload photo -->
                  <div class="my-auto">
                    <v-file-input
                      v-model="imageData"
                      accept="image/png, image/jpg, image/jpeg"
                      :placeholder="t('notifications.select_image')"
                      :label="t('notifications.image')"
                      :prepend-icon="icons.mdiCamera"
                      outlined
                      dense
                      hide-details
                      show-size
                      @change="onFileSelected($event, 'img')"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <options-buttom-tab
              v-if="hasPermission(57)"
              :tab-number="0"
              :is-mode-save-edit="true"
              :no-save="false"
              :info-required="false"
              :no-cancel="false"
              :no-arrow="true"
              @onCancel="onCancelButton"
              @onSave="onSaveAction"
            >
            </options-buttom-tab>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-trailing-spaces */
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { required } from '@core/utils/validation'
import { info, success } from '@core/utils/toasted'
import usePermissions from '@core/utils/usePermissions'

import { userSendNotificationForm } from '@api'

import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  setup() {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const dataGeneral = ref({})
    const option = ref(0)
    const form = ref(null)
    const imageData = ref(null)
    const urlImage = ref(null)
    const imageDataBg = ref(null)
    const urlImageBg = ref(null)

    const initialData = {
      title_spa: null,
      title_eng: null,
      description_spa: null,
      description_eng: null,
      button_label_spa: null,
      button_label_eng: null,
      image: null,
      background: null,
      handled_action: null,
      show_modal: false,
      only_fanaty_user: false,
    }

    dataGeneral.value = Object.assign(initialData, {
      title_spa: null,
      title_eng: null,
      description_spa: null,
      description_eng: null,
      button_label_spa: null,
      button_label_eng: null,
      image: null,
      background: null,
      handled_action: null,
      show_modal: false,
      only_fanaty_user: false,
    })

    const onCancelButton = () => {
      form.value.reset()
      dataGeneral.value = Object.assign(initialData, {
        title_spa: null,
        title_eng: null,
        description_spa: null,
        description_eng: null,
        button_label_spa: null,
        button_label_eng: null,
        image: null,
        background: null,
        handled_action: null,
        show_modal: false,
        only_fanaty_user: false,
      })
    }

    const cleanImage = type => {
      if (type === 'bg') {
        imageDataBg.value = null
        urlImageBg.value = null
      } else {
        imageData.value = null
        urlImage.value = null
      }
    }

    const onSaveAction = async () => {
      if (form.value.validate()) {
        const body = {
          title_spa: dataGeneral.value.title_spa,
          title_eng: dataGeneral.value.title_eng,
          description_spa: dataGeneral.value.description_spa,
          description_eng: dataGeneral.value.description_eng,
          button_label_spa: dataGeneral.value.button_label_spa,
          button_label_eng: dataGeneral.value.button_label_eng,
          handler_action: dataGeneral.value.handled_action,
          show_modal: dataGeneral.value.show_modal,
          only_fanaty_user: dataGeneral.value.only_fanaty_user,
        }
        const formData = new FormData()
        formData.append('data', JSON.stringify(body))
        if (imageDataBg.value) formData.append('img_background', imageDataBg.value)
        if (imageData.value) formData.append('img_central', imageData.value)

        const response = await userSendNotificationForm(formData)
        if (response.ok) {
          success(response.message)
          onCancelButton()
          cleanImage()
        }
      }
    }

    const onFileSelected = (e, selectType) => {
      try {
        cleanImage(selectType)
        if (e && e.size >= 262144) info('Max size of 256K!')
        const type = e ? e.type.split('/').pop() : null
        if (e && (type === 'jpg' || type === 'png' || type === 'jpeg') && e.size < 262144) {
          if (selectType === 'bg') {
            imageDataBg.value = e
            if (e) {
              urlImageBg.value = URL.createObjectURL(imageDataBg.value)
            }
          } else {
            imageData.value = e
            if (e) {
              urlImage.value = URL.createObjectURL(imageData.value)
            }
          }
        } else {
          cleanImage(selectType)
        }
      } catch (err) {
        console.error('error', err)
      }
    }

    return {
      form,
      dataGeneral,
      option,

      imageData,
      urlImage,
      imageDataBg,
      urlImageBg,

      required,
      onCancelButton,
      onSaveAction,
      cleanImage,
      onFileSelected,
      info,
      hasPermission,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/sport.scss';
</style>
